






































import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'

export default mixins(Section).extend({
  name: 'HTMLText2',
  computed: {
    getImgSize () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '100%'
      }

      return (this.data.configuration.wb_image_width__number__ <= 800)
        ? this.data.configuration.wb_image_width__number__ : 800
    }
  }
})
